import { useApolloClient } from '@apollo/client';
import { css } from '@emotion/react';
import { useRouter } from '@tanstack/react-router';
import { useAuthData, useUserRoles } from '@/auth';
import { UserRoles } from '@/graphql';
import { TextCutter } from '@/shared/atoms';
import { THEME } from '@/shared/constants';
import { useOnClickOutside, useQueryHelper, useToggleState } from '@/shared/hooks';
import { MenuTitle, removeToken } from '@/router';
import { MenuItem } from './MenuItem';
import { LanguageSelector } from './LanguageSelector';

export const UserPanel = () => {
  const client = useApolloClient();
  const { t } = useQueryHelper();
  const {
    auth: { email, role, name = '-', userId },
    dispatch
  } = useAuthData();
  const router = useRouter();
  const { status: isOpening, toggleStatus: togglePopover, close: closePopover } = useToggleState(false);
  const { ref } = useOnClickOutside<HTMLDivElement>(closePopover);
  const { isAdminStaffAgencyTalentAgencyPartner, isAdvertiser } = useUserRoles(); // shared with User Route permissions

  const handleSignOut = () => {
    removeToken();
    dispatch({ type: 'LOGOUT' });
    router.history.push('/sign-in');
    client.clearStore();
  };

  return (
    <div css={{ position: 'relative' }} ref={ref}>
      <div css={[styles.panel, { visibility: isOpening ? 'visible' : 'hidden' }]}>
        <div css={styles.heading}>
          {role ? <span css={styles.role}>{t(`Option.${roleEnumToShortName(role)}`)}</span> : null}
          <TextCutter text={name} css={styles.name} />
          <TextCutter text={email} css={styles.email} />
        </div>

        <div css={styles.settings}>
          {isAdminStaffAgencyTalentAgencyPartner || isAdvertiser ? (
            <MenuItem
              key="user-settings"
              keyPrefix=""
              title={MenuTitle.UserSettings}
              to={isAdvertiser ? '/user-settings' : '/user/$id'}
              params={{
                id: userId?.toString()
              }}
              onClick={closePopover}
              css={styles.menuItem}
            />
          ) : null}
          <LanguageSelector />
          <MenuItem
            key="sign-out"
            title={MenuTitle.SignOut}
            keyPrefix="Button"
            onClick={handleSignOut}
            css={styles.menuItem}
          />
        </div>
      </div>
      <div css={styles.username} onClick={togglePopover}>
        <span data-letters={name?.charAt(0).toUpperCase()} css={styles.iconWithLetter} />
        <TextCutter text={name} />
      </div>
    </div>
  );
};

const roleEnumToShortName = (role: UserRoles | null) => {
  switch (role) {
    case UserRoles.ADMINISTRATOR:
      return 'Admin';
    case UserRoles.STAFF:
      return 'Staff';
    case UserRoles.ADVERTISER:
      return 'Advertiser';
    case UserRoles.AGENCY:
      return 'Agency';
    case UserRoles.TALENT_AGENCY:
      return 'Talent Agency';
    case UserRoles.PARTNER:
      return 'Partner';

    default:
      return '';
  }
};

const styles = {
  username: css({
    display: 'flex',
    alignItems: 'baseline',
    gap: '8px',
    fontWeight: 600,
    fontSize: '14px',
    padding: '16px 0 16px 16px',
    cursor: 'pointer',
    ':hover': {
      background: '#F6F8FA'
    }
  }),
  heading: css({
    display: 'flex',
    flexDirection: 'column',
    padding: '11px 16px',
    borderBottom: THEME.border.base,
    color: THEME.text.colors.black.lv1,
    fontWeight: 400
  }),
  role: css({
    fontSize: '11px',
    lineHeight: '100%',
    color: THEME.text.colors.gray.lv3
  }),
  name: css({
    fontWeight: 600,
    fontSize: '12px',
    marginTop: '4px'
  }),
  email: css({
    fontSize: '11px'
  }),
  panel: css({
    position: 'absolute',
    width: THEME.container.menu.width,
    maxHeight: '191px',
    bottom: '53px',
    backgroundColor: THEME.background.colors.white,
    boxShadow: '-1px -3px 5px rgba(0, 0, 0, 0.08)'
  }),
  iconWithLetter: css({
    ':before': {
      content: 'attr(data-letters)',
      display: 'inline-block',
      width: '20px',
      height: '20px',
      lineHeight: '20px',
      textAlign: 'center',
      borderRadius: '50%',
      background: '#27313b',
      verticalAlign: 'middle',
      color: THEME.text.colors.white,
      fontWeight: 700,
      fontSize: '10px'
    }
  }),
  settings: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    padding: '8px',
    maxHeight: '110px',
    '.menu-item-title': {
      fontSize: '12px',
      fontWeight: 600
    }
  }),
  menuItem: css({
    height: '36px',
    margin: 0
  })
};
